<template>
  <div>
    <section class="hero is-light header">
      <div class="hero-body">
        <div class="container">
          <div class="header-title">Terms &amp; Conditions</div>   
          <div class="header-subtitle">Our user agreement explained in details.</div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content">
          <h3 class="title">1. Terms</h3>
          <p>
            By accessing this web site, you are agreeing to be bound by these
            web site Terms and Conditions of Use, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this site. The
            materials contained in this web site are protected by applicable
            copyright and trade mark law.
          </p>
          <h3 class="title">2. Use License</h3>
          <ol type="a">
            <li>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on {{ $mySite.title }}'s web
              site for personal, non-commercial transitory viewing only. This is
              the grant of a license, not a transfer of title, and under this
              license you may not:
              <ol type="i">
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </li>
                <li>
                  attempt to decompile or reverse engineer any software
                  contained on {{ $mySite.title }}'s web site;
                </li>
                <li>
                  remove any copyright or other proprietary notations from the
                  materials; or
                </li>
                <li>
                  transfer the materials to another person or "mirror" the
                  materials on any other server.
                </li>
              </ol>
            </li>
            <li>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by {{ $mySite.title }} at
              any time. Upon terminating your viewing of these materials or upon
              the termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </li>
          </ol>
          <h3 class="title">3. Disclaimer</h3>
          <ol type="a">
            <li>
              The materials on {{ $mySite.title }}'s web site are provided "as
              is". {{ $mySite.title }} makes no warranties, expressed or
              implied, and hereby disclaims and negates all other warranties,
              including without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights. Further, {{ $mySite.title }} does not warrant or make any
              representations concerning the accuracy, likely results, or
              reliability of the use of the materials on its Internet web site
              or otherwise relating to such materials or on any sites linked to
              this site.
            </li>
          </ol>
          <h3 class="title">4. Limitations</h3>
          <p>
            In no event shall {{ $mySite.title }} or its suppliers be liable for
            any damages (including, without limitation, damages for loss of data
            or profit, or due to business interruption,) arising out of the use
            or inability to use the materials on {{ $mySite.title }}'s Internet
            site, even if {{ $mySite.title }} or a
            {{ $mySite.title }} authorized representative has been notified
            orally or in writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you.
          </p>
          <h3 class="title">5. Revisions and Errata</h3>
          <p>
            The materials appearing on {{ $mySite.title }}'s web site could
            include technical, typographical, or photographic errors.
            {{ $mySite.title }} does not warrant that any of the materials on
            its web site are accurate, complete, or current.
            {{ $mySite.title }} may make changes to the materials contained on
            its web site at any time without notice. {{ $mySite.title }} does
            not, however, make any commitment to update the materials.
          </p>
          <h3 class="title">6. Links</h3>
          <p>
            {{ $mySite.title }} has not reviewed all of the sites linked to its
            Internet web site and is not responsible for the contents of any
            such linked site. The inclusion of any link does not imply
            endorsement by {{ $mySite.title }} of the site. Use of any such
            linked web site is at the user's own risk.
          </p>
          <h3 class="title">7. Site Terms of Use Modifications</h3>
          <p>
            {{ $mySite.title }} may revise these terms of use for its web site
            at any time without notice. By using this web site you are agreeing
            to be bound by the then current version of these Terms and
            Conditions of Use.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "terms",
  metaInfo() {
    return {
      title: this.$mySite.title,
      titleTemplate: "Terms & Conditions | %s",
      htmlAttrs: {
        lang: "en"
      },
      meta: [
        {
          name: "description",
          content:
            "By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use"
        },
        {
          property: "og:title",
          content: this.$mySite.title,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content:
            "By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use",
          vmid: "og:description"
        },
        {
          property: "og:image",
          content: this.$mySite.image,
          vmid: "og:image"
        },
        {
          property: "og:url",
          content: this.$mySite.url,
          vmid: "og:url"
        }
      ]
    };
  } //end meta
};
</script>
